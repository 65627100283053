export default class SafetyInspectionEquipmentDetails {
    id: number;

    equipmentNumber: string | undefined;

    inspectionId: number;

    locationId: number;

    constructor(init?: Partial<SafetyInspectionEquipmentDetails>) {
        this.id = init?.id || 0;
        this.equipmentNumber = init?.equipmentNumber || undefined;
        this.inspectionId = init?.inspectionId || 0;
        this.locationId = init?.locationId || 0;
    }
}
