import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import SafetyInspectionResult from '@/domain/enums/SafetyInspectionResult';
import SafetyInspectionResponse from '@/domain/SafetyInspectionResponse';
import SafetyInspectionShipmentDetails from '@/domain/SafetyInspectionShipmentDetails';
import TrailerInspectionDTO from '@/dtos/TrailerInspectionDTO';
import SafetyInspectionEquipmentDetails from '@/domain/safetyInspection/SafetyInspectionEquipmentDetails';
import EquipmentInspectionDTO from '@/dtos/EquipmentInspectionDTO';

export default class SafetyInspection {
    public id: number;

    public start: Date;

    public end?: Date;

    public reportedBy: number;

    public inspectionType: SafetyInspectionType;

    public shipmentDetails?: SafetyInspectionShipmentDetails;

    public equipmentDetails?: SafetyInspectionEquipmentDetails;

    public equipmentInspectionExpiration?: number;

    public locationId: number;

    public responses: SafetyInspectionResponse[];

    public result?: SafetyInspectionResult;

    constructor(init?: Partial<SafetyInspection>) {
        this.id = init?.id || 0;
        this.start = init?.start || new Date();
        this.end = init?.end || undefined;
        this.reportedBy = init?.reportedBy || 0;
        this.inspectionType = init?.inspectionType || SafetyInspectionType.PreLoad;
        this.shipmentDetails = init?.shipmentDetails || new SafetyInspectionShipmentDetails();
        this.locationId = init?.locationId || 0;
        this.responses = init?.responses || [];
        this.result = init?.result || undefined;
        this.equipmentDetails = init?.equipmentDetails || new SafetyInspectionEquipmentDetails();
        this.equipmentInspectionExpiration = init?.equipmentInspectionExpiration;
    }

    public toTrailerInspectionDTO(): TrailerInspectionDTO {
        return new TrailerInspectionDTO(this);
    }

    public toEquipmentInspectionDTO(): EquipmentInspectionDTO {
        return new EquipmentInspectionDTO(this);
    }
}
