import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import ShipmentDTO from '@/modules/floortrak/domain/jigsaw/dto/ShipmentDTO';
import Shipment from '@/domain/Shipment';
import TransactionDTO from '@/modules/floortrak/domain/jigsaw/dto/TransactionDTO';
import { downloadFile } from '@/functions/DownloadDocuments';
import { PutAwayDTO } from '@/dtos/PutAwayDTO';
import Transaction from '@/domain/Transaction';
import { ReceivingAction } from '@/modules/floortrak/domain/enums/ReceivingAction';
import InboundShipment from '@/domain/InboundShipment';

export default class ReceivingApiService {
    public async createReceivingShipment(shipment: ShipmentDTO): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'post',
            data: shipment,
            url: `${API_SERVICE_URL}/receiving`,
        });

        return new DataAccessResponseClass<Shipment>(response).response;
    }

    public async getTransactionReceipt(id: number, shipDateLabel: string): Promise<DataAccessResponse<Blob>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${API_SERVICE_URL}/receiving/transactions/${id}/receipt`,
            responseType: 'blob',
            params: { shipDateLabel },
        });

        const blobData: Blob = response.data;
        const fileName = `inbound_receipt_${id}.pdf`;
        await downloadFile(fileName, blobData);

        return new DataAccessResponseClass<Blob>(response).response;
    }

    public async findReceivingShipmentForReceiptFunctions(transactionId: number, toLocationId: number, action: ReceivingAction): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'get',
            url: `${API_SERVICE_URL}/receiving/shipment/${action}/transaction/${transactionId}/destination/${toLocationId}`,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async updateTransaction(dto: TransactionDTO): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/receiving/update-transaction`,
            data: dto,
        });

        return new DataAccessResponseClass<Transaction>(response, Transaction).response;
    }

    public async finalizeEstimate(dto: TransactionDTO): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'put',
            url: `${API_SERVICE_URL}/receiving/finalize-estimate`,
            data: dto,
        });

        return new DataAccessResponseClass<Transaction>(response, Transaction).response;
    }

    public async submitEstimate(dto: TransactionDTO): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'put',
            url: `${API_SERVICE_URL}/receiving/submit-estimate`,
            data: dto,
        });

        return new DataAccessResponseClass<Transaction>(response, Transaction).response;
    }

    public async blindReceiptAdjustments(dto: TransactionDTO): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'put',
            url: `${API_SERVICE_URL}/receiving/blind-receipt-adjustments`,
            data: dto,
        });

        return new DataAccessResponseClass<Transaction>(response, Transaction).response;
    }

    public async submitPutAway(dto: PutAwayDTO): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'put',
            url: `${API_SERVICE_URL}/receiving/put-away`,
            data: dto,
        });

        return new DataAccessResponseClass<Transaction>(response, Transaction).response;
    }

    public async submitCloseout(transactionId: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'put',
            url: `${API_SERVICE_URL}/receiving/closeout/${transactionId}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async confirmExistingInboundTransaction(transactionId: number): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/receiving/confirm-existing/${transactionId}`,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async updateShipment(dto: ShipmentDTO): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/receiving/update-existing-shipment`,
            data: dto,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async updateConfirmedShipment(dto: ShipmentDTO, transactionId: number): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/receiving/update-confirmed-existing-shipment/${transactionId}`,
            data: dto,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async getInboundShipments(toLocationId: number): Promise<DataAccessResponse<InboundShipment[]>> {
        const response: AxiosResponse<DataAccessResponse<InboundShipment[]>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/receiving/inbound/destination/${toLocationId}`,
        });

        return new DataListAccessResponseClass<InboundShipment>(response, InboundShipment).response;
    }
}
