import useMaxIntCompute from '@/composable/useMaxIntCompute';
import { useNotification } from '@/composable/useNotifications';
import Shipment from '@/domain/Shipment';
import ReceivingService from '@/services/ReceivingService';
import { ReceivingAction } from '../domain/enums/ReceivingAction';
import { getTranslation } from '@/services/TranslationService';

export default function useReceivingSearch(postSearchAction: (shipment: Shipment) => void) {
    const receivingService = new ReceivingService();
    const notification = useNotification();
    const { valueLessThanMax } = useMaxIntCompute();

    async function fetchReceivingShipment(searchInput: number, action: ReceivingAction) {
        if (valueLessThanMax(searchInput)) {
            const shipment = await receivingService.findReceivingShipment(searchInput, action);
            if (shipment) {
                postSearchAction(shipment);
            }
        } else {
            notification.showError(`${searchInput} ${getTranslation('core.validation.isNotValidTransactionId')}`);
        }
    }

    return {
        fetchReceivingShipment,
    };
}
