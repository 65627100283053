import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-458f9e8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question-row" }
const _hoisted_2 = {
  key: 0,
  class: "prompt-text"
}
const _hoisted_3 = { class: "question" }
const _hoisted_4 = ["id", "name"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "question" }
const _hoisted_7 = ["id", "name"]
const _hoisted_8 = ["for"]
const _hoisted_9 = { style: {"display":"flex","height":"54px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_button_upload_image = _resolveComponent("button-upload-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, {
          cols: "9",
          class: "question-text"
        }, {
          default: _withCtx(() => [
            (_ctx.state.showPrompt)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, " *" + _toDisplayString(_ctx.state.promptText), 1))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.inspectionResponse.question.questionText), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_b_col, {
          cols: "3",
          class: "question-response"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createElementVNode("input", {
                id: `question-${_ctx.inspectionResponse.questionId}-yes`,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inspectionResponse.answer) = $event)),
                name: `question-${_ctx.inspectionResponse.questionId}`,
                type: "radio",
                class: "btn-check",
                autocomplete: "off",
                value: true,
                onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.answerGiven && _ctx.answerGiven(...args)))
              }, null, 40, _hoisted_4), [
                [_vModelRadio, _ctx.inspectionResponse.answer]
              ]),
              _createElementVNode("label", {
                class: "btn btn-outline btn-outline-secondary",
                for: `question-${_ctx.inspectionResponse.questionId}-yes`
              }, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.yes')), 9, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                id: `question-${_ctx.inspectionResponse.questionId}-no`,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inspectionResponse.answer) = $event)),
                name: `question-${_ctx.inspectionResponse.questionId}`,
                type: "radio",
                class: "btn-check",
                autocomplete: "off",
                value: false,
                onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.answerGiven && _ctx.answerGiven(...args)))
              }, null, 40, _hoisted_7), [
                [_vModelRadio, _ctx.inspectionResponse.answer]
              ]),
              _createElementVNode("label", {
                class: "btn btn-outline btn-outline-secondary",
                for: `question-${_ctx.inspectionResponse.questionId}-no`
              }, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.no')), 9, _hoisted_8)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.state.collectData)
      ? (_openBlock(), _createBlock(_component_b_row, {
          key: 0,
          class: "data-collection-row"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, { cols: "9" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_textarea, {
                  id: `additionalPromptText-${_ctx.inspectionResponse.questionId}`,
                  modelValue: _ctx.inspectionResponse.additionalPromptText,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inspectionResponse.additionalPromptText) = $event)),
                  "max-length": 255
                }, null, 8, ["id", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, {
              cols: "3",
              class: "images"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inspectionResponse.images, (image) => {
                    return (_openBlock(), _createBlock(_component_thumbnail, {
                      key: image.full,
                      "image-url-full": image.full,
                      "image-url-thumb": image.thumb,
                      "encoded-image": image.base64String,
                      "encoded-image-type": image.type,
                      style: {"margin":"0px 5px"}
                    }, null, 8, ["image-url-full", "image-url-thumb", "encoded-image", "encoded-image-type"]))
                  }), 128))
                ]),
                _createVNode(_component_button_upload_image, {
                  "button-text": _ctx.getTitleCaseTranslation('core.button.uploadImages'),
                  class: "image-button",
                  "button-variant": "secondary",
                  onOnUpload: _ctx.onUploadInspectionImage
                }, null, 8, ["button-text", "onOnUpload"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}