export default class WarehousePutAwayDTO {
    public locationId: number = 0;

    public itemId: number = 0;

    public trackedItemId: number = 0;

    public floorLocationId: number = 0;

    public putAwayDateTime?: Date;

    public inboundTransactionId: number = 0;

    public outboundTransactionId?: number;

    constructor(init: WarehousePutAwayDTO) {
        this.locationId = init.locationId;
        this.itemId = init.itemId;
        this.trackedItemId = init.trackedItemId;
        this.floorLocationId = init.floorLocationId;
        this.putAwayDateTime = init.putAwayDateTime;
        this.inboundTransactionId = init.inboundTransactionId;
        this.outboundTransactionId = init.outboundTransactionId;
    }
}
