import { ref } from 'vue';

const isLoading = ref<boolean>(false);

export default function useLoading() {
    function setLoading(value: boolean) {
        isLoading.value = value;
    }

    return {
        isLoading,
        setLoading,
    };
}
