import FloorLocationApiService from '@/services/api/FloorLocationApiService';
import FloorLocation from '@/domain/FloorLocation';
import Location from '@/domain/Location';
import {DataAccessResponse} from '@/types/api/DataAccessResponse';

export default class FloorLocationService {
    private floorLocationService: FloorLocationApiService;

    constructor() {
        this.floorLocationService = new FloorLocationApiService();
    }

    public async addNewFloorLocation(floorLocation: FloorLocation): Promise< { floorLocation: FloorLocation; success: boolean } > {
        const response = await this.floorLocationService.addFloorLocation(floorLocation);
        return { floorLocation: response.data, success: response.success };
    }

    public async updateFloorLocation(floorLocation: FloorLocation): Promise< { floorLocation: FloorLocation; success: boolean }> {
        const response = await this.floorLocationService.updateFloorLocation(floorLocation);
        return { floorLocation: response.data, success: response.success };
    }

    public async getFloorLocationsForLocation(location: Location): Promise< { floorLocations: Array<FloorLocation>; success: boolean }> {
        const response = await this.floorLocationService.getFloorLocationsForLocation(location.id);
        return { floorLocations: response.data, success: response.success };
    }
}
