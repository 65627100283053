import SafetyInspectionAction from '@/domain/enums/SafetyInspectionAction';

export default class SafetyInspectionQuestion {
    public id: number;

    public yesAction: SafetyInspectionAction;

    public noAction: SafetyInspectionAction;

    public yesPromptText: string;

    public noPromptText: string;

    public order: number;

    public questionText: string;

    constructor(init?: Partial<SafetyInspectionQuestion>) {
        this.id = init?.id || 0;
        this.yesAction = init?.yesAction || SafetyInspectionAction.Continue;
        this.noAction = init?.noAction || SafetyInspectionAction.Continue;
        this.yesPromptText = init?.yesPromptText || '';
        this.noPromptText = init?.noPromptText || '';
        this.order = init?.order || 0;
        this.questionText = init?.questionText || '';
    }
}
