import coreStore from '@/store/CoreStore';

type TagCallback = (tags: string[]) => void;

export default class TagListener {
    private chars: Array<string> = [];

    private _endScanKey = 'Enter';

    public rfidPrefix = coreStore.getInstance().metaDataStore.rfidPrefix;

    private tags: string[] = [];

    private readonly callback!: TagCallback;

    private timerHandle?: ReturnType<typeof setInterval>;

    private keypressHandlerReference?: (e: KeyboardEvent) => void;

    private disableTextInput: boolean = false;

    constructor(callback: TagCallback) {
        this.callback = callback;
        this.init();
    }

    public startListener(disableTextInput: boolean = false) {
        this.disableTextInput = disableTextInput;
        this.keypressHandlerReference = (e: KeyboardEvent) => this.captureKeyPress(e);
        document.addEventListener('keypress', this.keypressHandlerReference);
        this.timerHandle = setInterval(() => this.processTags(), 1000);
    }

    public stopListener() {
        this.init();
        clearInterval(this.timerHandle);
        if (this.keypressHandlerReference) {
            document.removeEventListener('keypress', this.keypressHandlerReference);
        }
    }

    private init() {
        this.chars = [];
        this.tags = [];
    }

    private captureKeyPress(e: KeyboardEvent) {
        // prevent barcode listener when user is focused in an input field
        const { activeElement } = document;
        const inputs = ['input', 'select', 'textarea'];
        const inputInFocus = activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1;

        if (inputInFocus && activeElement && activeElement.tagName.toLowerCase() === 'button') {
            (activeElement as HTMLElement).blur();
        }

        if (!inputInFocus || this.disableTextInput) {
            if (e.key === this.endScanKey && this.chars.length > 0) {
                e.preventDefault();
                const newTag = this.chars.join('').trim();
                const processedTag = this.stripRfidPrefix(newTag);
                this.tags.push(processedTag);
                this.chars = [];
            } else {
                this.chars.push(e.key);
            }
        }
    }

    private processTags() {
        if (this.tags.length > 0) {
            const scanned = [...this.tags];
            this.tags = [];
            this.callback(scanned);
        }
    }

    public stripRfidPrefix(fullTag: string): string {
        if (fullTag.substring(0, this.rfidPrefix.length) === this.rfidPrefix) {
            return fullTag.substring(this.rfidPrefix.length);
        }
        return fullTag;
    }

    get endScanKey(): string {
        return this._endScanKey;
    }
}
