enum SafetyInspectionType {
    PreLoad = 1,
    PostLoad,
    PreUnload,
    PostUnload,
    SafetyOnly,
    EquipmentInspection,
}

export default SafetyInspectionType;
