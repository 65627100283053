import { DTOBuilder } from '@/modules/floortrak/domain/jigsaw/dto/DTOBuilder';
import SafetyInspection from '@/domain/SafetyInspection';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import SafetyInspectionResult from '@/domain/enums/SafetyInspectionResult';
import SafetyInspectionShipmentDetails from '@/domain/SafetyInspectionShipmentDetails';
import InspectionResponseDTO from '@/dtos/InspectionResponseDTO';

export default class TrailerInspectionDTO extends DTOBuilder {
    public id: number;

    public start: Date;

    public end?: Date;

    public reportedBy: number;

    public inspectionType: SafetyInspectionType;

    public shipmentDetails?: SafetyInspectionShipmentDetails;

    public locationId: number;

    public responses: InspectionResponseDTO[];

    public result?: SafetyInspectionResult;

    constructor(inspection: SafetyInspection) {
        super();
        this.id = inspection.id;
        this.start = inspection.start;
        this.end = inspection.end;
        this.reportedBy = inspection.reportedBy;
        this.inspectionType = inspection.inspectionType;
        this.shipmentDetails = inspection.shipmentDetails;
        this.locationId = inspection.locationId;
        this.result = inspection.result || undefined;
        this.responses = inspection.responses.map((response) => response.toDTO());
    }
}
