
import {
    computed, defineComponent, onBeforeMount, onMounted, PropType, reactive, watch,
} from 'vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import Carrier from '@/domain/Carrier';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import SafetyInspectionResult from '@/domain/enums/SafetyInspectionResult';
import SafetyInspection from '@/domain/SafetyInspection';
import SafetyInspectionShipmentDetails from '@/domain/SafetyInspectionShipmentDetails';
import SafetyInspectionResponse from '@/domain/SafetyInspectionResponse';
import Shipment from '@/domain/Shipment';

import SafetyInspectionService from '@/services/SafetyInspectionService';

import BCol from '@/components/bootstrap-library/BCol.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import SafetyInspectionQuestionnaireResponse from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaireResponse.vue';

import coreStore from '@/store/CoreStore';
import TagInput from '@/components/inputs/TagInput.vue';
import SafetyInspectionEquipmentDetails from '@/domain/safetyInspection/SafetyInspectionEquipmentDetails';
import BButton from '@/components/bootstrap-library/BButton.vue';

const spinner = require('@/assets/broken-circle-loader.gif');

type State = {
    loading: boolean;
    inspection: SafetyInspection;
    canContinue: boolean;
    shouldReject: boolean;
    continuing: boolean;
    rejecting: boolean;
    equipmentNumber: string;
    equipmentSelected: boolean;
};

export default defineComponent({
    name: 'safety-inspection-questionnaire',
    components: {
        BButton,
        TagInput,
        SafetyInspectionQuestionnaireResponse,
        BCol,
        BFormInput,
        BRow,
        BSpinner,
        DropdownAutocompleteSingleSelect,
    },
    props: {
        modelValue: {
            type: Boolean,
            default: () => false,
        },
        carriers: {
            type: Array as PropType<Carrier[]>,
            default: () => [],
        },
        inspectionType: {
            type: Number as PropType<SafetyInspectionType>,
            required: true,
        },
        shipment: {
            type: Object as PropType<Shipment>,
            default: () => new Shipment(),
        },
        inspectionTitle: {
            type: String,
            required: true,
        },
        modifyCarrierAndTrailer: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ['canceled', 'rejected', 'passed', 'update:modelValue'],
    setup(props, context) {
        const state = reactive<State>({
            loading: false,
            inspection: new SafetyInspection(),
            canContinue: false,
            shouldReject: false,
            continuing: false,
            rejecting: false,
            equipmentNumber: '',
            equipmentSelected: false,
        });

        const areCarrierDetailsFilledOut = computed(() => !!(props.shipment?.carrier && props.shipment?.trailer));

        const isEquipmentInspection = computed(() => props.inspectionType === SafetyInspectionType.EquipmentInspection);
        const canContinue = computed(() => state.canContinue && (isEquipmentInspection.value || areCarrierDetailsFilledOut.value));
        const canReject = computed(() => state.shouldReject && (isEquipmentInspection.value || areCarrierDetailsFilledOut.value));

        const { profileStore } = coreStore.getInstance();

        const safetyInspectionService = new SafetyInspectionService();

        async function setupInspection(): Promise<boolean> {
            state.loading = true;
            const { questions, success } = isEquipmentInspection.value
                ? await safetyInspectionService.getQuestionsForEquipmentInspection(state.equipmentNumber)
                : await safetyInspectionService.getQuestionsForInspectionType(props.inspectionType);

            state.inspection = new SafetyInspection({
                inspectionType: props.inspectionType,
                reportedBy: profileStore.id,
                start: new Date(),
                shipmentDetails: props.shipment
                    ? new SafetyInspectionShipmentDetails({
                        shipmentId: props.shipment.id,
                        carrierId: props.shipment.carrier?.id,
                        trailerNumber: props.shipment.trailer,
                    })
                    : undefined,
                locationId: profileStore.userLocation.id,
                responses: questions.map((q) => SafetyInspectionResponse.fromQuestion(q)),
            });
            state.loading = false;

            return success;
        }

        onMounted(async () => {
            if (!isEquipmentInspection.value) {
                await setupInspection();
            }
        });

        onBeforeMount(() => {
            if (
                isEquipmentInspection.value
                && profileStore.equipmentInspection
                && profileStore.equipmentInspection.result === SafetyInspectionResult.Passed
                && profileStore.equipmentInspection.equipmentInspectionExpiration
                && profileStore.equipmentInspection.equipmentInspectionExpiration > Date.now()
            ) {
                context.emit('update:modelValue', false);
            }
        });

        watch(
            () => state.inspection.responses.map((response) => ({ isAnswered: response.isAnswered, canContinue: response.canContinue, shouldReject: response.shouldReject })),
            (rejected) => {
                state.canContinue = rejected.every((r) => r.isAnswered && r.canContinue);
                state.shouldReject = rejected.every((r) => r.isAnswered) && rejected.some((r) => r.shouldReject);
            },
            { immediate: true },
        );
        watch(
            () => props.shipment,
            (value) => {
                if (value && state.inspection.shipmentDetails) {
                    state.inspection.shipmentDetails.shipmentId = value.id;
                    state.inspection.shipmentDetails.carrierId = value.carrier?.id ?? 0;
                    state.inspection.shipmentDetails.trailerNumber = value.trailer;
                }
            },
            { deep: true, immediate: true },
        );
        watch(
            () => props.modelValue,
            async (value) => {
                if (!value && !isEquipmentInspection.value) {
                    await setupInspection();
                }
            },
        );

        function escape() {
            context.emit('canceled');
            context.emit('update:modelValue', false);
            state.inspection = new SafetyInspection();
        }

        async function tryAddTrailerInspection(eventNameToEmit: 'rejected' | 'passed') {
            const response = await safetyInspectionService.addTrailerInspection(state.inspection);
            if (response.success) {
                context.emit('update:modelValue', false);
                context.emit(eventNameToEmit, response.inspection);
                state.inspection = new SafetyInspection();
            }
        }

        async function tryAddEquipmentInspection(eventNameToEmit: 'rejected' | 'passed') {
            state.inspection.equipmentDetails = new SafetyInspectionEquipmentDetails({
                id: 0,
                equipmentNumber: state.equipmentNumber,
                locationId: profileStore.userLocation.id,
            });
            const response = await safetyInspectionService.addEquipmentInspection(state.inspection);
            if (response.success) {
                context.emit('update:modelValue', false);
                context.emit(eventNameToEmit, response.inspection);
                profileStore.equipmentInspection = response.inspection;
                state.inspection = new SafetyInspection();
            }
        }

        async function reject() {
            state.rejecting = true;
            state.inspection.end = new Date();
            state.inspection.result = SafetyInspectionResult.Rejected;
            if (!isEquipmentInspection.value) {
                await tryAddTrailerInspection('rejected');
            } else {
                await tryAddEquipmentInspection('rejected');
            }
            state.rejecting = false;
        }

        async function confirm() {
            if (state.inspection.responses.length === 0 && isEquipmentInspection.value) {
                const equipmentInspection = await safetyInspectionService.getEquipmentInspectionForShift(state.equipmentNumber, profileStore.userLocation.id);

                if (equipmentInspection.success && equipmentInspection?.data?.result === SafetyInspectionResult.Passed) {
                    profileStore.equipmentInspection = equipmentInspection.data;
                    context.emit('update:modelValue', false);
                } else if (equipmentInspection.success) {
                    const success = await setupInspection();

                    if (success) {
                        state.equipmentSelected = true;
                    }
                }
            } else {
                state.continuing = true;
                state.inspection.end = new Date();
                state.inspection.result = SafetyInspectionResult.Passed;

                if (!isEquipmentInspection.value) {
                    await tryAddTrailerInspection('passed');
                } else {
                    await tryAddEquipmentInspection('passed');
                }

                state.continuing = false;
            }
        }

        function selectCarrier(carrier: Carrier | null) {
            if (props.shipment) {
                props.shipment.carrier = carrier ?? undefined;
                props.shipment.carrierId = carrier?.id ?? 0;
            }
        }

        function clearCarrier() {
            if (props.shipment) {
                props.shipment.carrier = undefined;
                props.shipment.carrierId = 0;
            }
        }

        async function setEquipmentNumberFromScan(value: string) {
            state.equipmentNumber = value;
            state.continuing = true;
            await setupInspection();
            state.continuing = false;
        }

        function clearSelection() {
            state.equipmentNumber = '';
            state.equipmentSelected = false;
            state.inspection.responses = [];
        }

        return {
            state,
            spinner,
            getTranslation,
            getTitleCaseTranslation,
            escape,
            reject,
            canContinue,
            canReject,
            isEquipmentInspection,
            confirm,
            selectCarrier,
            clearCarrier,
            setEquipmentNumberFromScan,
            clearSelection,
        };
    },
});
