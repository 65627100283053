
import { defineComponent } from 'vue';
import OrbisCard from '@/components/OrbisCard.vue';

export default defineComponent({
    name: 'floor-trak-orbis-card',
    extends: OrbisCard,
    props: {
        title: { type: String, default: undefined },
        showBack: Boolean,
    },
    emits: ['goBack'],
    setup(props, context) {
        function goBack() {
            context.emit('goBack');
        }

        return {
            goBack,
        };
    },
});
