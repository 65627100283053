
import { defineComponent, reactive } from 'vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import InspectionAction from '@/domain/enums/SafetyInspectionAction';
import InspectionResponse from '@/domain/SafetyInspectionResponse';

import ButtonUploadImage from '@/components/buttons/ButtonUploadImage.vue';
import Thumbnail from '@/components/Thumbnail.vue';

import { UploadImageEvent } from '@/domain/ImageUpload';

type State = {
    collectData: boolean;
    showPrompt: boolean;
    promptText: string;
};

export default defineComponent({
    name: 'safety-inspection-questionnaire-response',
    components: {
        ButtonUploadImage,
        Thumbnail,
    },
    props: {
        inspectionResponse: {
            type: InspectionResponse,
            required: true,
        },
    },
    emits: ['completed'],
    setup(props) {
        const state = reactive<State>({
            collectData: false,
            showPrompt: false,
            promptText: '',
        });

        function onUploadInspectionImage(value: UploadImageEvent) {
            props.inspectionResponse.addImage(value);
        }

        function answerGiven() {
            const userAnswersWithYes = props.inspectionResponse.answer;
            const action = userAnswersWithYes ? props.inspectionResponse.question.yesAction : props.inspectionResponse.question.noAction;

            switch (action) {
            case InspectionAction.CollectDataAndProceed:
            case InspectionAction.CollectDataAndReject:
                state.collectData = true;
                state.promptText = userAnswersWithYes ? props.inspectionResponse.question.yesPromptText : props.inspectionResponse.question.noPromptText;
                state.showPrompt = true;
                break;
            case InspectionAction.ForceCorrection:
                state.collectData = false;
                state.promptText = userAnswersWithYes ? props.inspectionResponse.question.yesPromptText : props.inspectionResponse.question.noPromptText;
                state.showPrompt = true;
                props.inspectionResponse.clearImages();
                break;
            case InspectionAction.Continue:
            default:
                state.collectData = false;
                state.showPrompt = false;
                props.inspectionResponse.clearImages();
                break;
            }
        }

        return {
            state,
            getTranslation,
            getTitleCaseTranslation,
            answerGiven,
            onUploadInspectionImage,
        };
    },
});
