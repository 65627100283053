import { DTOBuilder } from '@/modules/floortrak/domain/jigsaw/dto/DTOBuilder';
import SafetyInspectionResponse from '@/domain/SafetyInspectionResponse';

export default class InspectionResponseDTO extends DTOBuilder {
    public id!: number;

    public answer!: boolean;

    public additionalPromptText: string;

    public imageBase64Strings: Array<string> = [];

    public questionId!: number;

    constructor(inspectionResponse: SafetyInspectionResponse) {
        super();
        this.id = inspectionResponse.id;
        this.answer = inspectionResponse.answer!;
        this.additionalPromptText = inspectionResponse.additionalPromptText;
        this.questionId = inspectionResponse.questionId;

        if (inspectionResponse.images) {
            this.imageBase64Strings = inspectionResponse.images.filter((img) => img.base64String).map((img) => img.base64String) as Array<string>;
        }
    }
}
