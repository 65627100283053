
import {
    computed, defineComponent, onBeforeMount, onBeforeUnmount, reactive,
} from 'vue';
import Shipment from '@/domain/Shipment';
import TagListener from '@/modules/floortrak/services/TagListener';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import Location from '@/domain/Location';
import Transaction from '@/domain/Transaction';
import { ItemType } from '@/domain/enums/ItemType';
import Item from '@/domain/Item';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import ReceivingService from '@/services/ReceivingService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BForm from '@/components/bootstrap-library/BForm.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import router from '@/router';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import CoreStore from '@/store/CoreStore';
import TrackedItemTagModal from '@/components/TrackedItemTagModal.vue';
import TrackedItem from '@/domain/TrackedItem';
import FloortrakReceivingTagScanHandler from '@/services/tag-scanning/scan-handler/FloortrakReceivingTagScanHandler';
import useReceivingSearch from '@/modules/floortrak/composables/useReceivingSearch';
import FloorTrakOrbisCard from '@/components/FloorTrakOrbisCard.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import useLoading from '@/modules/floortrak/composables/useLoading';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import { ReceivingAction } from '@/modules/floortrak/domain/enums/ReceivingAction';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import WarehousePutAwayDTO from '@/dtos/WarehousePutAwayDTO';
import FloorLocationService from '@/services/FloorLocationService';
import FloorLocation from '@/domain/FloorLocation';
import ItemService from '@/services/ItemService';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import { useNotification } from '@/composable/useNotifications';
import TrackedItemService from '@/services/tag-scanning/TrackedItemService';

type State = {
    loading: boolean;
    search: number | null;
    shipment: Shipment;
    showSearch: boolean;
    searching: boolean;
    putAwayItems: Array<WarehousePutAwayDTO>;
    tags: Array<TrackedItem>;
    showPrintLabelsModal: boolean;
    labelQuantity: number | undefined;
    printingLabels: boolean;
    showEquipmentInspection: boolean;
    selectedFloorLocation: FloorLocation | undefined;
    selectedPutAwayItem: TrackedItem | undefined;
    availableFloorLocations: Array<FloorLocation>;
    transactionItems: Array<Item>;
    showLocationSearch: boolean;
    vacantFloorLocations: Array<FloorLocation>;
    sharedFloorLocations: Array<FloorLocation>;
    transactionLineItem: string | null;
};

interface ItemTableData {
    item: Item;
    floorLocation: string;
    putAwayQtys: number;
    scannedTags: Array<TrackedItem>;
}

export default defineComponent({
    name: 'put-away',
    components: {
        BSpinner,
        BFormInput,
        BTable,
        TrackedItemTagModal,
        FloorTrakOrbisCard,
        SmartTrakFooter,
        BModal,
        BRow,
        BCol,
        BButton,
        BForm,
        SafetyInspectionQuestionnaire,
        DropdownAutocompleteSingleSelect,
    },
    props: {
        transactionNumber: {
            type: String,
            required: false,
            default: undefined,
        }, // this is a route param, it has to be a string => convert to number onSearch
    },
    setup(props) {
        const receivingService = new ReceivingService();
        const { setLoading } = useLoading();
        const { forkliftCertified, certExpirationDate } = CoreStore.getInstance().profileStore;
        const { confirm } = useDialogBox();
        const floorLocationService = new FloorLocationService();
        const itemService = new ItemService();
        const trackedItemService = new TrackedItemService();
        const notification = useNotification();

        const state = reactive<State>({
            loading: false,
            search: null,
            shipment: new Shipment(),
            showSearch: true,
            searching: false,
            putAwayItems: [],
            tags: [],
            labelQuantity: undefined,
            showPrintLabelsModal: false,
            printingLabels: false,
            showEquipmentInspection: true,
            selectedFloorLocation: undefined,
            selectedPutAwayItem: undefined,
            availableFloorLocations: [],
            transactionItems: [],
            showLocationSearch: false,
            vacantFloorLocations: [],
            sharedFloorLocations: [],
            transactionLineItem: null,
        });

        const tagListener = new TagListener(searchFloorLocationsAndItems);
        const transaction = computed(
            (): Transaction => {
                if (state.shipment.transactions.length > 0) {
                    return state.shipment.transactions[0] as Transaction;
                }
                return new Transaction();
            },
        );
        const tags = computed((): Array<TrackedItem> => state.tags as Array<TrackedItem>);

        function getCurrentTagsForItem(itemId: number, floorLocationId: number): Array<TrackedItem> {
            const currentTags: Array<TrackedItem> = [];
            const floorLocationsForItem = state.putAwayItems.filter((tag) => tag.itemId === itemId && tag.floorLocationId === floorLocationId);
            const tagsForItem = tags.value.filter((tag) => tag.itemId === itemId);

            floorLocationsForItem.forEach((x) => {
                const index = tagsForItem.findIndex((tag) => tag.id === x.trackedItemId);
                if (index >= 0) {
                    currentTags.push(tagsForItem[index]);
                }
            });

            return currentTags;
        }

        function getFloorLocationName(locationId: number): string {
            return state.availableFloorLocations.find((fl) => fl.id === locationId)?.name ?? '';
        }

        async function getItemById(itemId: number) : Promise<Item> {
            const item = await itemService.getItemById(itemId);

            return item.item ?? new Item();
        }

        function mapTransLinesToTableData(lines: Array<ItemTableData>): Array<ItemTableData> {
            return lines.map((line) => ({
                item: line.item,
                floorLocation: line.floorLocation,
                putAwayQtys: line.putAwayQtys,
                scannedTags: line.scannedTags,
            }));
        }

        const putAwayItems = computed((): Array<WarehousePutAwayDTO> => state.putAwayItems as Array<WarehousePutAwayDTO>);

        const tableData = computed(
            (): Array<ItemTableData> => {
                if (state.putAwayItems) {
                    const sortedLines: Array<ItemTableData> = [];
                    putAwayItems.value.forEach((dto) => {
                        const foundItem = state.transactionItems.find((i) => i.id === dto.itemId)!;
                        const floorLocationName = getFloorLocationName(dto.floorLocationId);
                        const foundLine = sortedLines.findIndex((line) => line.item.displayName === foundItem.displayName && line.floorLocation === floorLocationName);

                        if (foundLine >= 0) {
                            sortedLines[foundLine].putAwayQtys++;
                        } else {
                            const newLine: ItemTableData = {
                                item: foundItem,
                                floorLocation: floorLocationName,
                                putAwayQtys: 1,
                                scannedTags: getCurrentTagsForItem(dto.itemId, dto.floorLocationId),
                            };

                            sortedLines.push(newLine);
                        }
                    });
                    return mapTransLinesToTableData(sortedLines);
                }
                return [];
            },
        );

        const vacantFloorLocations = computed(
            (): Array<FloorLocation> => {
                const floorLocations: Array<FloorLocation> = [];
                state.availableFloorLocations.forEach((loc) => {
                    const occupiedLocation = putAwayItems.value.find((dto) => dto.floorLocationId === loc.id);
                    if (occupiedLocation === undefined) {
                        floorLocations.push(loc);
                    }
                });
                return floorLocations;
            },
        );

        const sharedFloorLocations = computed(
            (): Array<FloorLocation> => {
                const floorLocations: Array<FloorLocation> = [];
                if (state.selectedPutAwayItem) {
                    const sharedLocationDto = putAwayItems.value.find((dto) => dto.itemId === state.selectedPutAwayItem?.itemId);
                    if (sharedLocationDto) {
                        const putAwayFloorLocations = putAwayItems.value.filter((x) => x.itemId === sharedLocationDto.itemId);
                        if (putAwayFloorLocations.length > 0) {
                            putAwayFloorLocations.forEach((loc) => {
                                const floorLocationToAdd = state.availableFloorLocations.find((fl) => fl.id === loc.floorLocationId);
                                const locationAlreadyAdded = floorLocations.findIndex((fl) => fl.id === loc.floorLocationId);
                                if (locationAlreadyAdded === -1 && floorLocationToAdd) {
                                    floorLocations.push(floorLocationToAdd);
                                }
                            });
                        }
                    }
                }
                return floorLocations;
            },
        );

        function addTag(trackedItem: TrackedItem): void {
            state.selectedPutAwayItem = trackedItem;
        }

        const tagHandler = new FloortrakReceivingTagScanHandler(transaction, tags, addTag);

        const fields: Array<BTableField<ItemTableData & { action: string }>> = [
            {
                key: 'item',
                width: '150px',
                label: getTitleCaseTranslation('core.domain.item'),
            },
            {
                key: 'floorLocation',
                width: '75px',
                label: getTitleCaseTranslation('core.common.floorLocation'),
            },
            {
                key: 'putAwayQtys',
                width: '75px',
                label: getTitleCaseTranslation('core.common.putAway'),
            },
            {
                key: 'scannedTags',
                width: '75px',
                label: getTitleCaseTranslation('core.common.tags'),
            },
        ];

        async function searchFloorLocationsAndItems(scannerInput: Array<string>) {
            const foundFloorLocation = state.availableFloorLocations.find((fl) => fl.name === scannerInput[0]);
            if (foundFloorLocation) {
                state.selectedFloorLocation = foundFloorLocation;
            } else {
                const trackedItem = await trackedItemService.getTrackedItemByBarcode(scannerInput[0]);
                if (trackedItem) {
                    const existingLine = transaction.value.getTransactionLineByItem(trackedItem.item);
                    if (existingLine) {
                        // We should only put away items that exist in TransactionLines
                        // This is for TRIVIUM WAREHOUSE ONLY and WILL need to be updated in the future
                        // e.g receiving packaging - in the future we need to be able to put away anything
                        await tagHandler.performSearchManagedWarehouse(scannerInput);
                    } else {
                        // TODO: get rid of this when we have more customers using this page
                        notification.showError(getTranslation('core.validation.itemNotOnTransaction'));
                    }
                }
            }
        }

        function closeSearch() {
            state.search = null;
            state.showSearch = false;
            if (transaction.value.transactionLines.length === 1) {
                state.transactionLineItem = transaction.value.transactionLines[0].item.displayName;
            }
        }

        function postSearchSuccess(shipment: Shipment) {
            state.shipment = shipment;
            closeSearch();
        }

        const { fetchReceivingShipment } = useReceivingSearch(postSearchSuccess);

        async function searchExisting() {
            state.loading = true;
            if (state.search) {
                state.searching = true;
                await fetchReceivingShipment(state.search, ReceivingAction.PUT_AWAY);
                state.putAwayItems = await receivingService.getWarehousePutAwayByTransactionId(transaction.value.id);
                state.putAwayItems.forEach(async (item) => {
                    state.transactionItems.push(await getItemById(item.itemId));
                    const trackedItem = await receivingService.getTrackedItemById(item.trackedItemId);
                    if (trackedItem.success) {
                        state.tags.push(trackedItem.data);
                    }
                });
                const floorLocations = await floorLocationService.getFloorLocationsForLocation(transaction.value.toLocation);
                if (floorLocations.success) {
                    state.availableFloorLocations = floorLocations.floorLocations;
                }
                state.searching = false;
            }
            state.loading = false;
        }

        onBeforeMount(async () => {
            tagListener.startListener();
            state.shipment.addTransaction(new Transaction());
            state.shipment.transactions[0].toLocation = new Location({
                id: 0,
                name: '',
            });

            state.loading = true;

            if (!forkliftCertified) {
                await confirm({
                    title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                    message: getTranslation('core.validation.forkliftCertificationMissing'),
                    vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            } else if (!certExpirationDate || new Date(certExpirationDate) < new Date(Date.now())) {
                await confirm({
                    title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                    message: getTranslation('core.validation.forkliftCertificationExpired'),
                    vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }

            if (props.transactionNumber) {
                state.showSearch = false;
                state.search = parseInt(props.transactionNumber, 10);
                await searchExisting();
                if (!state.shipment?.id) {
                    state.showSearch = true;
                }
            }

            state.loading = false;
        });

        onBeforeUnmount(() => tagListener.stopListener());

        async function removeTag(tag: TrackedItem) {
            const dtoToDelete = state.putAwayItems.find((dto) => dto.trackedItemId === tag.id);
            if (dtoToDelete) {
                const response = await receivingService.removeTrackedItemFromTransaction(dtoToDelete);
                if (response) {
                    let stateTagIndex = state.tags.findIndex((x) => x.id === tag.id);
                    state.tags.splice(stateTagIndex, 1);
                    stateTagIndex = state.putAwayItems.findIndex((x) => x.trackedItemId === tag.id);
                    state.putAwayItems.splice(stateTagIndex, 1);
                }
            }
        }

        async function putAway() {
            const itemAlreadyAdded = state.putAwayItems.findIndex((i) => i.trackedItemId === state.selectedPutAwayItem?.id);
            if (state.selectedFloorLocation && state.selectedPutAwayItem && itemAlreadyAdded < 0) {
                const putAwayPayload = {
                    locationId: transaction.value.toLocationId,
                    itemId: state.selectedPutAwayItem.item.id,
                    trackedItemId: state.selectedPutAwayItem.id,
                    floorLocationId: state.selectedFloorLocation.id,
                    inboundTransactionId: transaction.value.id,
                };

                const response = await receivingService.submitWarehousePutAway(putAwayPayload);

                if (response.success) {
                    state.tags.push(state.selectedPutAwayItem);
                    state.putAwayItems.push(putAwayPayload);
                    state.transactionItems.push(await getItemById(putAwayPayload.itemId));
                    state.selectedFloorLocation = undefined;
                    state.selectedPutAwayItem = undefined;
                }
            }

            if (itemAlreadyAdded >= 0) {
                notification.showError(getTranslation('core.validation.itemIsAlreadyAdded', state.selectedPutAwayItem?.barcode));

                state.selectedFloorLocation = undefined;
                state.selectedPutAwayItem = undefined;
            }
        }

        function escape() {
            closeSearch();
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        async function onCloseout() {
            const confirmResponse = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.common.proceedToCloseout'),
                vHtml: true,
            });

            if (confirmResponse) {
                await router.push({
                    name: FloorTrakRouteTypes.RECEIVING.CLOSEOUT,
                    params: { transactionNumber: transaction.value.id },
                });
            }
        }

        async function printInboundReceiptAndGoHome() {
            const confirmResponse = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.common.confirmSave'),
                vHtml: true,
            });

            if (confirmResponse) {
                state.loading = true;
                setLoading(true);
                await receivingService.getTransactionReceipt(transaction.value.id);
                setLoading(false);
                state.loading = false;

                await router.push({
                    name: FloorTrakRouteTypes.RECEIVING.CLOSEOUT,
                    params: { transactionNumber: transaction.value.id },
                });
            }
        }

        function cancelSafetyInspection() {
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function toggleLocationSearchModal() {
            state.vacantFloorLocations = vacantFloorLocations.value;
            state.sharedFloorLocations = sharedFloorLocations.value;
            state.showLocationSearch = !state.showLocationSearch;
        }

        function selectFloorLocation(floorLocation: FloorLocation | undefined) {
            state.selectedFloorLocation = floorLocation;
        }

        return {
            state,
            transaction,
            fields,
            ItemType,
            tableData,
            searchExisting,
            putAway,
            escape,
            getCurrentTagsForItem,
            removeTag,
            onCloseout,
            getTranslation,
            getTitleCaseTranslation,
            printInboundReceiptAndGoHome,
            SafetyInspectionType,
            cancelSafetyInspection,
            getItemById,
            toggleLocationSearchModal,
            selectFloorLocation,
            vacantFloorLocations,
            sharedFloorLocations,
        };
    },
});
