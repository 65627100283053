import { normalizeDate } from '@/functions/date';

export default class InboundShipment {
    constructor(init?: Partial<InboundShipment>) {
        Object.assign(this, init);

        if (init) {
            this.actualDepartureDate = normalizeDate(init?.actualDepartureDate);
        }
    }

    public shipmentId!: number;
    public transactionId!: number;
    public fromLocationId!: number;
    public actualDepartureDate!: Date;
    public carrier?: string;
    public licensePlateNumber?: string;
    public seal?: string;
}
