import { DTOBuilder } from '@/modules/floortrak/domain/jigsaw/dto/DTOBuilder';
import TrackedItem from '@/domain/TrackedItem';

export default class CreateTrackedItemDTO extends DTOBuilder {
    public itemId!: number;

    public barcode!: number;

    constructor(trackedItem: TrackedItem) {
        super();
        this.map<CreateTrackedItemDTO, TrackedItem>(this, trackedItem);
    }
}
