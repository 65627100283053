export default class ProductionPartLoadInventory {
    public id!: number;
    public locationId!: number;
    public itemId!: number;
    public trackedItemId!: number;
    public floorLocationId!: number;
    public putAwayDateTime?: Date;
    public inboundTransactionId!: number;
    public outboundTransactionId?: number;

    constructor(init?: ProductionPartLoadInventory) {
        if (init) {
            this.id = init.id;
            this.locationId = init.locationId;
            this.itemId = init.itemId;
            this.trackedItemId = init.trackedItemId;
            this.floorLocationId = init.floorLocationId;
            this.putAwayDateTime = init.putAwayDateTime;
            this.inboundTransactionId = init.inboundTransactionId;
            this.outboundTransactionId = init.outboundTransactionId;
        }
    }
}
