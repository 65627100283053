import { DTOBuilder } from '@/modules/floortrak/domain/jigsaw/dto/DTOBuilder';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import SafetyInspectionEquipmentDetails from '@/domain/safetyInspection/SafetyInspectionEquipmentDetails';
import SafetyInspection from '@/domain/SafetyInspection';
import InspectionResponseDTO from '@/dtos/InspectionResponseDTO';
import SafetyInspectionResult from '@/domain/enums/SafetyInspectionResult';

export default class EquipmentInspectionDTO extends DTOBuilder {
    public id: number;

    public start: Date;

    public end?: Date;

    public reportedBy: number;

    public inspectionType: SafetyInspectionType;

    public equipmentDetails?: SafetyInspectionEquipmentDetails;

    public locationId: number;

    public responses: InspectionResponseDTO[];

    public result?: SafetyInspectionResult;

    public equipmentInspectionExpiration?: number;

    constructor(inspection: SafetyInspection) {
        super();
        this.id = inspection.id;
        this.start = inspection.start;
        this.end = inspection.end;
        this.reportedBy = inspection.reportedBy;
        this.inspectionType = inspection.inspectionType;
        this.equipmentDetails = inspection.equipmentDetails;
        this.locationId = inspection.locationId;
        this.responses = inspection.responses.map((response) => response.toDTO());
        this.result = inspection.result;
        this.equipmentInspectionExpiration = inspection.equipmentInspectionExpiration;
    }
}
