import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import SafetyInspection from '@/domain/SafetyInspection';
import SafetyInspectionQuestion from '@/domain/SafetyInspectionQuestion';

import SafetyInspectionApiService from '@/services/api/SafetyInspectionApiService';

import CoreStore from '@/store/CoreStore';
import ProfileStore from '@/store/modules/ProfileStore';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class SafetyInspectionService {
    private safetyInspectionApiService: SafetyInspectionApiService;
    private profileStore: ProfileStore;

    constructor() {
        this.safetyInspectionApiService = new SafetyInspectionApiService();
        this.profileStore = CoreStore.getInstance().profileStore;
    }

    public async getQuestionsForInspectionType(inspectionType: SafetyInspectionType): Promise<{ questions: SafetyInspectionQuestion[]; success: boolean }> {
        const response = await this.safetyInspectionApiService.getQuestionsForInspectionType(inspectionType, this.profileStore.userLanguage?.culture ?? 'en');

        return { success: response.success, questions: response.data };
    }

    public async getQuestionsForEquipmentInspection(equipmentNumber: string): Promise<{ questions: SafetyInspectionQuestion[]; success: boolean }> {
        const response = await this.safetyInspectionApiService.getQuestionsForEquipmentInspection(equipmentNumber, this.profileStore.userLocation.id, this.profileStore.userLanguage?.culture ?? 'en');

        return { success: response.success, questions: response.data };
    }

    public async addTrailerInspection(inspection: SafetyInspection): Promise<{ inspection: SafetyInspection; success: boolean }> {
        const response = await this.safetyInspectionApiService.addTrailerInspection(inspection.toTrailerInspectionDTO());

        return { success: response.success, inspection: response.data };
    }

    public async addEquipmentInspection(inspection: SafetyInspection): Promise<{ inspection: SafetyInspection; success: boolean }> {
        const response = await this.safetyInspectionApiService.addEquipmentInspection(inspection.toEquipmentInspectionDTO());

        return { success: response.success, inspection: response.data };
    }

    public async trailerInspectionExistsForShipment(shipmentId: number): Promise<boolean> {
        const response = await this.safetyInspectionApiService.trailerInspectionExistsForShipment(shipmentId);

        return response.data;
    }

    public async trailerInspectionOfTypePassesForShipment(shipmentId: number, inspectionType: SafetyInspectionType): Promise<boolean> {
        const response = await this.safetyInspectionApiService.trailerInspectionOfTypePassesForShipment(shipmentId, inspectionType);

        return response.data;
    }

    public async associateShipmentWithTrailerInspection(shipmentId: number, inspectionId: number): Promise<boolean> {
        const response = await this.safetyInspectionApiService.associateShipmentWithTrailerInspection(shipmentId, inspectionId);

        return response.data;
    }

    public async getEquipmentInspectionForShift(equipmentId: string, locationId: number): Promise<DataAccessResponse<SafetyInspection>> {
        const response = await this.safetyInspectionApiService.getEquipmentInspectionForShift(equipmentId, locationId, this.profileStore.id);

        return response;
    }
}
