
import {
    defineComponent, ref,
} from 'vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import TagListener from '@/modules/floortrak/services/TagListener';

export default defineComponent({
    name: 'tag-input',
    components: {
        BButton,
        BFormInput,
    },
    extends: BFormInput,
    props: {
        searchable: Boolean,
        modelValue: { type: String, required: true },
    },
    emits: ['scan', 'clear', 'update:modelValue'],
    setup(props, context) {
        // TODO: this component doesnt need to have it's state outside this component, remove modelValue at some point

        const scannedBarcode = ref('');

        const tagListener = new TagListener(submit);

        function update(value: string) {
            if (!props.modelValue) {
                scannedBarcode.value = '';
            }
            context.emit('update:modelValue', value);
        }

        function submit() {
            const processedTag = tagListener.stripRfidPrefix(props.modelValue);
            scannedBarcode.value = processedTag;
            update(processedTag);
            context.emit('scan', processedTag);
        }

        function onKeyPress(e: KeyboardEvent) {
            if (e.key === tagListener.endScanKey) {
                submit();
            }
        }

        function clear() {
            scannedBarcode.value = '';
            context.emit('clear');
        }

        return {
            scannedBarcode,
            onKeyPress,
            update,
            clear,
            submit,
        };
    },
});
