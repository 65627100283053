import { useNotification } from '@/composable/useNotifications';
import TrackedItem from '@/domain/TrackedItem';
import TrackedItemService from '@/services/tag-scanning/TrackedItemService';

export default abstract class TagScanHandler {
    protected notification = useNotification();

    protected trackedItemService: TrackedItemService;

    protected scannerInput: Array<string>;

    protected constructor() {
        this.trackedItemService = new TrackedItemService();
        this.scannerInput = new Array<string>();
    }

    protected abstract handleScan(returnedTags: Array<TrackedItem>): Array<string>;

    protected abstract handleScanManagedWarehouse(returnedTags: Array<TrackedItem>): Array<string>;

    protected abstract fetchTags(): Promise<Array<TrackedItem> | undefined>;

    public async performSearch(input: Array<string>) {
        this.scannerInput = input;
        const trackedItems = await this.fetchTags();
        if (trackedItems?.length) {
            const duplicates = this.handleScan(trackedItems);
            // Our service functions are responsible for dispatching error if it wasn't found. This just checks duplicates.
            duplicates.forEach((tag) => this.notification.showWarning(`Tag: ${tag} has already been scanned`));
        }
    }

    public async performSearchManagedWarehouse(input: Array<string>) {
        this.scannerInput = input;
        const trackedItems = await this.fetchTags();
        if (trackedItems?.length) {
            this.handleScanManagedWarehouse(trackedItems);
        }
    }
}
