import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c21a25c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tracked-item-modal-component-container" }
const _hoisted_2 = { class: "btn-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showModal) = $event)),
      centered: "",
      title: _ctx.title,
      "hide-footer": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.tags')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
          return (_openBlock(), _createElementBlock("div", {
            key: tag.id,
            class: "tag-container"
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("span", null, [
                _createVNode(_component_faicon, {
                  style: {"font-size":"30px"},
                  icon: "barcode"
                })
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(tag.barcode), 1)
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_b_button, {
                onClick: ($event: any) => (_ctx.remove(tag))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.remove')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ])
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_b_button, {
      disabled: _ctx.disabled,
      class: "open-tags-modal-btn",
      onClick: _ctx.openModal
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.tagsButtonText), 1)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}