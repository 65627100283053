import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import TrackedItem from '@/domain/TrackedItem';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from './dataAccessResponse';
import Item from '@/domain/Item';
import CreateTrackedItemDTO from '@/dtos/CreateNewTrackedItemDTO';

export default class TrackedItemApiService {
    public async searchByBarcodes(barcodes: string[]): Promise<DataAccessResponse<Array<TrackedItem>>> {
        const response: AxiosResponse<DataAccessResponse<Array<TrackedItem>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/TrackedItems`,
            params: { barcodes },
        });

        // we need to initialize the Item inside of TrackItem so it has all of the class properties
        const darResponse = new DataListAccessResponseClass<TrackedItem>(response, TrackedItem).response;
        darResponse.data.forEach((d) => {
            d.item = new Item(d.item);
        });
        return darResponse;
    }

    public async getTrackedItemsInRouteConfig(barcodes: string[], fromId: number, toId: number): Promise<DataAccessResponse<Array<TrackedItem>>> {
        const response: AxiosResponse<DataAccessResponse<Array<TrackedItem>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/TrackedItems/in-route-config`,
            params: { barcodes, fromId, toId },
        });

        // we need to initialize the Item inside of TrackItem so it has all of the class properties
        const darResponse = new DataListAccessResponseClass<TrackedItem>(response, TrackedItem).response;
        darResponse.data.forEach((d) => {
            d.item = new Item(d.item);
        });
        return darResponse;
    }

    public async updateTrackedItem(trackedItem: TrackedItem): Promise<DataAccessResponse<TrackedItem>> {
        const response: AxiosResponse<DataAccessResponse<TrackedItem>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/TrackedItems`,
            data: trackedItem,
        });

        return new DataAccessResponseClass<TrackedItem>(response, TrackedItem).response;
    }

    public async getTrackedItemByBarcode(barcode: string): Promise<DataAccessResponse<TrackedItem>> {
        const response: AxiosResponse<DataAccessResponse<TrackedItem>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/TrackedItems/by-single-barcode`,
            params: { barcode },
        });

        return new DataAccessResponseClass<TrackedItem>(response, TrackedItem).response;
    }

    public async createTrackedItem(trackedItem: CreateTrackedItemDTO): Promise<DataAccessResponse<Number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/TrackedItems`,
            data: trackedItem,
        });

        return new DataAccessResponseClass<Number>(response, Number).response;
    }
}
