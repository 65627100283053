import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from './dataAccessResponse';
import SafetyInspection from '@/domain/SafetyInspection';
import SafetyInspectionQuestion from '@/domain/SafetyInspectionQuestion';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import TrailerInspectionDTO from '@/dtos/TrailerInspectionDTO';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import EquipmentInspectionDTO from '@/dtos/EquipmentInspectionDTO';

export default class SafetyInspectionApiService {
    public async getQuestionsForInspectionType(inspectionType: SafetyInspectionType, culture: string): Promise<DataAccessResponse<SafetyInspectionQuestion[]>> {
        const response: AxiosResponse<DataAccessResponse<SafetyInspectionQuestion[]>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/SafetyInspection/type/${inspectionType}/questions/${culture}`,
        });

        return new DataListAccessResponseClass<SafetyInspectionQuestion>(response, SafetyInspectionQuestion).response;
    }

    public async getQuestionsForEquipmentInspection(equipmentNumber: string, locationId: number, culture: string): Promise<DataAccessResponse<SafetyInspectionQuestion[]>> {
        const response: AxiosResponse<DataAccessResponse<SafetyInspectionQuestion[]>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/SafetyInspection/equipment-inspection/${equipmentNumber}/location/${locationId}/questions/${culture}`,
        });

        return new DataListAccessResponseClass<SafetyInspectionQuestion>(response, SafetyInspectionQuestion).response;
    }

    public async addTrailerInspection(inspection: TrailerInspectionDTO): Promise<DataAccessResponse<SafetyInspection>> {
        const response: AxiosResponse<DataAccessResponse<SafetyInspection>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/SafetyInspection/trailer`,
            data: inspection,
        });

        return new DataAccessResponseClass<SafetyInspection>(response, SafetyInspection).response;
    }

    public async addEquipmentInspection(inspection: EquipmentInspectionDTO): Promise<DataAccessResponse<SafetyInspection>> {
        const response: AxiosResponse<DataAccessResponse<SafetyInspection>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/SafetyInspection/equipment`,
            data: inspection,
        });

        return new DataAccessResponseClass<SafetyInspection>(response, SafetyInspection).response;
    }

    public async trailerInspectionExistsForShipment(shipmentId: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/SafetyInspection/trailer/shipment/${shipmentId}/exists`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async trailerInspectionOfTypePassesForShipment(shipmentId: number, inspectionType: SafetyInspectionType): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/SafetyInspection/trailer/shipment/${shipmentId}/type/${inspectionType}/passes`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async associateShipmentWithTrailerInspection(shipmentId: number, inspectionId: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/SafetyInspection/trailer/shipment/${shipmentId}/inspection/${inspectionId}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async getEquipmentInspectionForShift(equipmentId: string, locationId: number, userId: number) {
        const response: AxiosResponse<DataAccessResponse<SafetyInspection>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/SafetyInspection/equipment/${equipmentId}/location/${locationId}/user/${userId}/shift`,
        });

        return new DataAccessResponseClass<SafetyInspection>(response).response;
    }
}
