export default class SafetyInspectionShipmentDetails {
    public id: number;

    public carrierId: number;

    public shipmentId: number | undefined;

    public trailerNumber: string;

    constructor(init?: Partial<SafetyInspectionShipmentDetails>) {
        this.id = init?.id || 0;
        this.carrierId = init?.id || 0;
        this.shipmentId = init?.shipmentId || undefined;
        this.trailerNumber = init?.trailerNumber || '';
    }
}
