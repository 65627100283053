import InspectionResponseDTO from '@/dtos/InspectionResponseDTO';
import ImageUpload, { UploadImageEvent } from '@/domain/ImageUpload';
import SafetyInspectionQuestion from '@/domain/SafetyInspectionQuestion';
import SafetyInspectionAction from '@/domain/enums/SafetyInspectionAction';

export default class SafetyInspectionResponse {
    public id: number;

    public answer?: boolean;

    public images: ImageUpload[];

    public additionalPromptText: string;

    public question: SafetyInspectionQuestion;

    readonly maxImages = 3;

    constructor(init?: Partial<SafetyInspectionResponse>) {
        this.id = init?.id || 0;
        this.answer = init?.answer || undefined;
        this.images = init?.images || [];
        this.additionalPromptText = init?.additionalPromptText || '';
        this.question = init?.question || new SafetyInspectionQuestion();
        this.maxImages = 3;
    }

    public static fromQuestion(question: SafetyInspectionQuestion): SafetyInspectionResponse {
        return new SafetyInspectionResponse({
            id: 0,
            answer: undefined,
            images: [],
            additionalPromptText: '',
            question,
        });
    }

    public get questionId(): number {
        return this.question.id;
    }

    public addImage(value: UploadImageEvent): boolean {
        if (this.images.length < this.maxImages) {
            const image = ImageUpload.CreateBase64Image(value);

            if (!this.images.some((img) => img.base64String === image.base64String)) {
                this.images.push(image);
                return true;
            }
        }
        return false;
    }

    public clearImages() {
        this.images = [];
    }

    public get isAnswered(): boolean {
        return this.answer !== undefined;
    }

    public get canContinue(): boolean {
        let action = this.question.noAction;

        if (this.answer) {
            action = this.question.yesAction;
        }

        switch (action) {
        case SafetyInspectionAction.Continue:
            return true;
        case SafetyInspectionAction.CollectDataAndProceed:
            return this.additionalPromptText.trim().length > 0;
        case SafetyInspectionAction.ForceCorrection:
        case SafetyInspectionAction.CollectDataAndReject:
        default:
            return false;
        }
    }

    public get shouldReject(): boolean {
        let action = this.question.noAction;

        if (this.answer) {
            action = this.question.yesAction;
        }

        switch (action) {
        case SafetyInspectionAction.ForceCorrection:
            return true;
        case SafetyInspectionAction.CollectDataAndReject:
            return this.additionalPromptText.trim().length > 0;
        case SafetyInspectionAction.Continue:
        case SafetyInspectionAction.CollectDataAndProceed:
        default:
            return false;
        }
    }

    public toDTO(): InspectionResponseDTO {
        return new InspectionResponseDTO(this);
    }
}
