export const maxIntSize = 2147483647;

export default function useMaxIntCompute() {
    function valueLessThanMax(input: number):boolean {
        return input < maxIntSize;
    }

    return {
        valueLessThanMax,
    };
}
