
import {
    defineComponent, reactive, PropType, computed,
} from 'vue';
import TrackedItem from '@/domain/TrackedItem';
import useScreenSize from '@/composable/useScreenSize';
import sideBarStore from '@/components/sidebar/store/SidebarStore';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    showModal: boolean;
}

export default defineComponent({
    name: 'tracked-item-tag-modal',
    props: {
        tags: {
            type: Array as PropType<Array<TrackedItem>>,
            default: undefined,
        },
        title: {
            type: String,
            default: getTitleCaseTranslation('core.domain.itemTags'),
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['removeTag'],
    setup(props, ctx) {
        const { screenWidth } = useScreenSize();
        const sideBar = sideBarStore.getInstance();

        const shouldShortenTagText = computed(():boolean => {
            const sideBarIsVisible = sideBar.sideBarVisible;
            if (screenWidth.value <= 1500) {
                return true;
            }
            if (screenWidth.value > 1500 && screenWidth.value < 1800 && sideBarIsVisible) {
                return true;
            }
            return false;
        });

        const tagsButtonText = computed((): string => (shouldShortenTagText.value
            ? getTitleCaseTranslation('core.common.tags')
            : `${getTitleCaseTranslation('core.common.tags')}: ${props?.tags?.length}`));

        const state = reactive<State>({
            showModal: false,
        });

        function remove(tag: TrackedItem) {
            ctx.emit('removeTag', tag);
        }

        function openModal(): void {
            state.showModal = true;
        }

        function closeModal(): void {
            state.showModal = false;
        }

        return {
            state,
            remove,
            openModal,
            closeModal,
            tagsButtonText,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});
