import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_orbis_card = _resolveComponent("orbis-card")!

  return (_openBlock(), _createBlock(_component_orbis_card, _mergeProps({ class: "content-body" }, _ctx.$props, { onGoBack: _ctx.goBack }), _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (_, name) => {
      return {
        name: name,
        fn: _withCtx((slotData) => [
          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(slotData)), undefined, true)
        ])
      }
    })
  ]), 1040, ["onGoBack"]))
}