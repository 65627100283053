import Shipment from '@/domain/Shipment';
import { useNotification } from '@/composable/useNotifications';
import ReceivingApiService from '@/services/api/ReceivingApiService';
import Transaction from '@/domain/Transaction';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { PutAwayDTO } from '@/dtos/PutAwayDTO';
import { formatDateTimeUI } from '@/functions/date';
import coreStore from '@/store/CoreStore';
import { getTranslation } from '@/services/TranslationService';
import { ReceivingAction } from '@/modules/floortrak/domain/enums/ReceivingAction';
import InboundShipment from '@/domain/InboundShipment';
import WarehouseReceivingApiService from '@/services/api/WarehouseReceivingApiService';
import WarehousePutAwayDTO from '@/dtos/WarehousePutAwayDTO';
import ProductionPartLoadInventory from '@/domain/ProductionPartLoadInventory';
import TrackedItem from '@/domain/TrackedItem';

export default class ReceivingService {
    private receivingApiService: ReceivingApiService;
    private warehouseReceivingApiService: WarehouseReceivingApiService;

    private notification = useNotification();

    constructor() {
        this.receivingApiService = new ReceivingApiService();
        this.warehouseReceivingApiService = new WarehouseReceivingApiService();
    }

    public async createReceivingShipment(shipment: Shipment): Promise<{ shipment: Shipment; success: boolean }> {
        const response = await this.receivingApiService.createReceivingShipment(shipment.toDTO());
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.saveShipmentSuccess'));
        }
        return { success: response.success, shipment: response.data };
    }

    public async finalizeEstimate(transaction: Transaction): Promise<{ success: boolean; transaction: Transaction; }> {
        const dto = transaction.toDTO();
        const response = await this.receivingApiService.finalizeEstimate(dto);
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.domain.estimateFinalized'));
        }

        return { success: response.success, transaction: response.data };
    }

    public async submitEstimate(transaction: Transaction): Promise<DataAccessResponse<Transaction>> {
        const dto = transaction.toDTO();
        const response = await this.receivingApiService.submitEstimate(dto);

        return response;
    }

    public async blindReceiptAdjustments(transaction: Transaction): Promise<DataAccessResponse<Transaction>> {
        const dto = transaction.toDTO();
        const response = await this.receivingApiService.blindReceiptAdjustments(dto);

        return response;
    }

    public async submitPutAway(putAwayDTO: PutAwayDTO): Promise<{ updatedTransaction?: Transaction }> {
        const { data, success } = await this.receivingApiService.submitPutAway(putAwayDTO);
        const updatedTransaction = success ? data : undefined;
        return { updatedTransaction };
    }

    public async getTransactionReceipt(transactionId: number): Promise<DataAccessResponse<Blob>> {
        return this.receivingApiService.getTransactionReceipt(transactionId, formatDateTimeUI(new Date()));
    }

    public async submitCloseout(transactionId: number): Promise<boolean> {
        const response = await this.receivingApiService.submitCloseout(transactionId);
        return response.success;
    }

    public async confirmExistingTransaction(transactionId: number): Promise<{ success: boolean; shipment: Shipment; message: string }> {
        const response = await this.receivingApiService.confirmExistingInboundTransaction(transactionId);
        return { success: response.success, shipment: response.data, message: response.message };
    }

    public async findReceivingShipment(transactionId: number, action: ReceivingAction): Promise<Shipment | null> {
        const toLocationId = coreStore.getInstance().profileStore.userLocation.id;
        const response = await this.receivingApiService.findReceivingShipmentForReceiptFunctions(transactionId, toLocationId, action);
        if (response.success) {
            return response.data;
        }
        return null;
    }

    public async updateTransaction(transaction: Transaction): Promise<{ success: boolean; transaction: Transaction; message: string }> {
        const dto = transaction.toDTO();
        const response = await this.receivingApiService.updateTransaction(dto);
        return { success: response.success, transaction: response.data, message: response.message };
    }

    public async updateShipment(shipment: Shipment): Promise<{ success: boolean; shipment: Shipment; message: string }> {
        const dto = shipment.toDTO();
        const response = await this.receivingApiService.updateShipment(dto);
        return { success: response.success, shipment: response.data, message: response.message };
    }

    public async updateConfirmedShipment(shipment: Shipment, transactionId: number): Promise<{ success: boolean; shipment: Shipment; message: string }> {
        const dto = shipment.toDTO();
        const response = await this.receivingApiService.updateConfirmedShipment(dto, transactionId);
        return { success: response.success, shipment: response.data, message: response.message };
    }

    public async getInboundShipments(): Promise<{ success: boolean; inboundShipments: InboundShipment[]; message: string }> {
        const toLocationId = coreStore.getInstance().profileStore.userLocation.id;
        const response = await this.receivingApiService.getInboundShipments(toLocationId);
        return { success: response.success, inboundShipments: response.data, message: response.message };
    }

    public async submitWarehousePutAway(dto: WarehousePutAwayDTO): Promise<DataAccessResponse<ProductionPartLoadInventory>> {
        const response = await this.warehouseReceivingApiService.warehousePutAway(dto);
        return { success: response.success, data: response.data, message: response.message };
    }

    public async getWarehousePutAwayByTransactionId(transactionId: number): Promise<Array<WarehousePutAwayDTO>> {
        const response = await this.warehouseReceivingApiService.getWarehousePutAwayByTransactionId(transactionId);

        if (response.success) {
            return response.data;
        }

        return [];
    }

    public async getTrackedItemById(trackedItemId: number): Promise<DataAccessResponse<TrackedItem>> {
        const response = await this.warehouseReceivingApiService.getTrackedItemById(trackedItemId);

        return { success: response.success, data: response.data, message: response.message };
    }

    public async removeTrackedItemFromTransaction(dto: WarehousePutAwayDTO): Promise<boolean> {
        const response = await this.warehouseReceivingApiService.removeTrackedItemFromTransaction(dto);
        return response.success;
    }
}
