export enum ReceivingAction {
    NEW = 'new',
    EXISTING = 'existing',
    ESTIMATE = 'estimate',
    SORT = 'sort',
    PUT_AWAY = 'put-away',
    CLOSEOUT = 'closeout',
}

export default ReceivingAction;
